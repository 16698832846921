import "./Subtitle.css";

const Subtitle = () => {
  return (
    <div className="custom-subtitle">
      <h2 className="subtitle-centered-text">
        W związku z tym nie ma jednego operatora płatności, który byłby
        optymalnym rozwiązaniem dla wszystkich.
      </h2>
      <div className="background-line"></div>
    </div>
  );
};

export default Subtitle;

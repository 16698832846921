import { useRef, useState } from "react";
import "./App.css";
import Filter from "./components/Filter/Filter";
import Header from "./components/Header/Header";
import Subtitle from "./components/Subtitle/Subtitle";
import Text from "./components/Text/Text";
import CustomTable from "./components/Table/CustomTable";
import Title from "./components/Title/Title";
import { Group } from "./models/Group";
import { Information } from "./models/Information";
import { Provider } from "./models/Provider";
import card from "./images/Karta_płatnicza.png";

import "@fontsource/league-spartan";
import { NavMobile } from "./components/NavMobile/NavMobile";
import SubtitleGradient from "./components/Subtitle/SubtitleGradient";
import React from "react";

function App() {
  const Groups: Group[] = [
    {
      GroupId: "G1",
      Name: "Podstawowe informacje",
    },
    {
      GroupId: "G2",
      Name: "Funkcjonalności",
    },
    {
      GroupId: "G3",
      Name: "Dodatkowe informacje",
    },
  ];

  const Information: Information[] = [
    {
      InformationId: "I1",
      Name: "Opłata weryfikacyjna",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I2",
      Name: "Prowizja bazowa",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I3",
      Name: "Prowizja BLIK",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I4",
      Name: "Prowizja przelew",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I5",
      Name: "Prowizja karta płatnicza",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I6",
      Name: "Odzyskiwanie koszyków",
      GroupId: "G2",
      Unregistred: null,
    },
    {
      InformationId: "I7",
      Name: "Płatność przez link",
      GroupId: "G2",
      Unregistred: null,
    },
    {
      InformationId: "I8",
      Name: "Transakcje zagraniczne",
      GroupId: "G2",
      Unregistred: null,
    },
    {
      InformationId: "I9",
      Name: "Metody płatności",
      GroupId: "G2",
      Unregistred: null,
    },
    {
      InformationId: "I10",
      Name: "Działalność nierejstrowana",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I11",
      Name: "Kontakt telefoniczny",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I12",
      Name: "Największe współpracujące marki",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I13",
      Name: "Liczba wypłat w miesiącu",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I14",
      Name: "Prowizja od wypłaty",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I15",
      Name: "Opłata za zwrot produktu przez klienta",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I16",
      Name: "Waluty",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I17",
      Name: "Od kiedy na rynku",
      GroupId: "G3",
      Unregistred: null,
    },
    {
      InformationId: "I21",
      Name: "Minimalna prowizja za płatność",
      GroupId: "G1",
      Unregistred: false,
    },
    {
      InformationId: "I22",
      Name: "Opłata weryfikacyjna (działalność nier.)",
      GroupId: "G1",
      Unregistred: true,
    },
    {
      InformationId: "I18",
      Name: "Prowizja bazowa (działność nier.)",
      GroupId: "G1",
      Unregistred: true,
    },
    {
      InformationId: "I19",
      Name: "Prowizja BLIK (działność nier.)",
      GroupId: "G1",
      Unregistred: true,
    },
    {
      InformationId: "I20",
      Name: "Prowizja przelew (działność nier.)",
      GroupId: "G1",
      Unregistred: true,
    },
  ];

  const Providers: Provider[] = [
    {
      ProviderId: "P1",
      ProviderName: "HotPay",
      VerificationFee: { InformationId: "I1", Value: "10 zł" },
      CommissionBase: { InformationId: "I2", Value: "0,00 zł" },
      CommissionPercentBlik: { InformationId: "I3", Value: "0,95%" },
      CommissionPercentTransfer: { InformationId: "I4", Value: "0,95%" },
      UnregistredCommissionBase: { InformationId: "I18", Value: "0,30 zł" },
      UnregistredCommissionPercentBlik: {
        InformationId: "I19",
        Value: "2,45%",
      },
      UnregistredCommissionPercentTransfer: {
        InformationId: "I20",
        Value: "2,45%",
      },
      // CommissionPercentDebitCard: { InformationId: "I5", Value: "0,95%" },
      UnregistredCompany: { InformationId: "I10", Value: true },
      MinimumCommissionFee: { InformationId: "I21", Value: "0,3 zł" },
      UnregistredVerificationFee: { InformationId: "I22", Value: "10,00 zł" },
    },
    {
      ProviderId: "P2",
      ProviderName: "Przelewy24",
      VerificationFee: { InformationId: "I1", Value: "59 zł" },
      CommissionBase: { InformationId: "I2", Value: "0,30 zł" },
      CommissionPercentBlik: { InformationId: "I3", Value: "1,29%" },
      CommissionPercentTransfer: { InformationId: "I4", Value: "1,29%" },
      UnregistredCommissionBase: null,
      UnregistredCommissionPercentBlik: null,
      UnregistredCommissionPercentTransfer: null,
      // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,29%" },
      UnregistredCompany: { InformationId: "I10", Value: false },
      MinimumCommissionFee: null,
      UnregistredVerificationFee: null,
    },
    {
      ProviderId: "P3",
      ProviderName: "PayU",
      VerificationFee: { InformationId: "I1", Value: "10 zł" },
      CommissionBase: { InformationId: "I2", Value: "0,0 zł" },
      CommissionPercentBlik: { InformationId: "I3", Value: "1,3%" },
      CommissionPercentTransfer: { InformationId: "I4", Value: "1,3%" },
      UnregistredCommissionBase: null,
      UnregistredCommissionPercentBlik: null,
      UnregistredCommissionPercentTransfer: null,
      // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,25%" },
      UnregistredCompany: { InformationId: "I10", Value: false },
      MinimumCommissionFee: null,
      UnregistredVerificationFee: null,
    },
    {
      ProviderId: "P4",
      ProviderName: "AutoPay",
      VerificationFee: { InformationId: "I1", Value: "49 zł" },
      CommissionBase: { InformationId: "I2", Value: "0,25 zł" },
      CommissionPercentBlik: { InformationId: "I3", Value: "1,19%" },
      CommissionPercentTransfer: { InformationId: "I4", Value: "1,19%" },
      UnregistredCommissionBase: { InformationId: "I18", Value: "0,25 zł" },
      UnregistredCommissionPercentBlik: {
        InformationId: "I19",
        Value: "1,19%",
      },
      UnregistredCommissionPercentTransfer: {
        InformationId: "I20",
        Value: "1,19%",
      },
      // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,19%" },
      UnregistredCompany: { InformationId: "I10", Value: true },
      MinimumCommissionFee: null,
      UnregistredVerificationFee: { InformationId: "I22", Value: "199,00 zł" },
    },
    {
      ProviderId: "P5",
      ProviderName: "TPay",
      VerificationFee: { InformationId: "I1", Value: "49 zł" },
      CommissionBase: { InformationId: "I2", Value: "0,39 zł" },
      CommissionPercentBlik: { InformationId: "I3", Value: "0,99%" },
      CommissionPercentTransfer: { InformationId: "I4", Value: "0,99%" },
      UnregistredCommissionBase: { InformationId: "I18", Value: "0,39 zł" },
      UnregistredCommissionPercentBlik: {
        InformationId: "I19",
        Value: "1,59%",
      },
      UnregistredCommissionPercentTransfer: {
        InformationId: "I20",
        Value: "1,59%",
      },
      // CommissionPercentDebitCard: { InformationId: "I5", Value: "1,59%" },
      UnregistredCompany: { InformationId: "I10", Value: true },
      MinimumCommissionFee: null,
      UnregistredVerificationFee: { InformationId: "I22", Value: "10,00 zł" },
    },
  ];
  const [providers, setProviders] = useState<Provider[]>(Providers);
  const [unregistred, setUnregistred] = useState<boolean>(false);
  const [registred, setRegistred] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("payment");

  const calculateCommission = (
    numberOfTransaction: number,
    transactionValue: number
  ) => {
    const sortedProviders = [...providers].sort(
      (a, b) =>
        calculate(a, numberOfTransaction, transactionValue) -
        calculate(b, numberOfTransaction, transactionValue)
    );
    setProviders(sortedProviders);
  };

  const replaceToDecimal = (commissionPercent: string): number => {
    var commission = commissionPercent.replace("%", "");

    return parseFloat(commission) / 100;
  };

  const averageCommissionPercentage = (provider: Provider): number => {
    if (unregistred && !registred) {
      return (
        (replaceToDecimal(
          provider.UnregistredCommissionPercentBlik?.Value ?? "0"
        ) +
          // replaceToDecimal(provider.CommissionPercentDebitCard.Value) +
          replaceToDecimal(
            provider.UnregistredCommissionPercentTransfer?.Value ?? "0"
          )) /
        2
      );
    } else {
      return (
        (replaceToDecimal(provider.CommissionPercentBlik.Value) +
          // replaceToDecimal(provider.CommissionPercentDebitCard.Value) +
          replaceToDecimal(provider.CommissionPercentTransfer.Value)) /
        2
      );
    }
  };

  const calculate = (
    provider: Provider,
    numberOfTransaction: number,
    transactionValue: number
  ): number => {
    var averageCommission = averageCommissionPercentage(provider);

    var commision = averageCommission * transactionValue;

    if (unregistred && !registred) {
      commision =
        commision +
        parseFloat(provider.UnregistredCommissionBase?.Value ?? "0");
    } else {
      commision = commision + parseFloat(provider.CommissionBase.Value);
    }

    if (
      provider.MinimumCommissionFee != null &&
      commision < parseFloat(provider.MinimumCommissionFee.Value)
    ) {
      commision = parseFloat(provider.MinimumCommissionFee.Value);
    }

    return (
      numberOfTransaction * commision +
      parseFloat(provider.VerificationFee.Value)
    );
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const tableRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Header
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      {selectedTab === "payment" && (
        <>
          <Title
            text="Doskonale zdajemy sobie sprawę, 
            że każdy biznes jest inny."
          />
          <Subtitle />
          <SubtitleGradient />
          <div className="payment-check-button-box">
            <button
              onClick={() => scrollToSection(tableRef)}
              className="payment-check-button"
            >
              Sprawdzam
            </button>
          </div>
          <div className="tableViewRef" ref={tableRef}>
            <Text
              text="Przedstawiony w tabeli ranking został utworzony w oparciu o średnią wartość transakcji na rynku e-commerce w 2023 roku, która wynosiła X zł* [źródło!]."
              type="bold"
            />
          </div>

          <Text
            text="Na potrzeby obliczeń założono również liczbę transakcji na poziomie 100 miesięcznie."
            type="bold-long"
          />
          <Text
            text="Jeśli jednak specyfika Twojego biznesu jest inna to w poniższe pola możesz wprowadzić parametry swojego sklepu, aby dobrać rozwiązanie, które będzie optymalne dla Ciebie."
            type="normal-long"
          />
          <Filter
            calculateCommission={calculateCommission}
            registred={registred}
            setRegistred={setRegistred}
            unregistred={unregistred}
            setUnregistred={setUnregistred}
          />

          <CustomTable
            providers={providers}
            groups={Groups}
            information={Information}
            registred={registred}
            unRegistred={unregistred}
          />

          <div>
            <img className="background-card" src={card} alt="Karta płatnicza" />
          </div>
        </>
      )}
      {selectedTab === "accounting" && (
        <>
          <Title text="Funkcjonalność dostępna wkrótce." />
        </>
      )}
      {selectedTab === "account" && (
        <>
          <Title text="Funkcjonalność dostępna wkrótce." />
        </>
      )}
      {selectedTab === "cooperation" && (
        <>
          <Title text="Funkcjonalność dostępna wkrótce." />
        </>
      )}
      {selectedTab === "about" && (
        <>
          <Title text="Funkcjonalność dostępna wkrótce." />
        </>
      )}
      {selectedTab === "contact" && (
        <>
          <Title text="Funkcjonalność dostępna wkrótce." />
        </>
      )}
      {isMenuOpen ? (
        <div id="mobile-menu">
          <NavMobile
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            setSelectedTab={setSelectedTab}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default App;

import "./Title.css";

interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = (props) => {
  return (
    <div className="custom-title">
      <h1 className="centered-text">{props.text}</h1>
      <div className="background-line"></div>
    </div>
  );
};

export default Title;

import { routes } from "../../route";
import "../Header/Header.css";
import "./StaticTabs.css";

interface StaticTabsProps {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const StaticTabs: React.FC<StaticTabsProps> = (props) => {
  return (
    <nav className="static-tabs">
      <ul>
        {routes
          .filter((route) => route.main)
          .map((route) => {
            return (
              <li
                className="static-tabs-item"
                id={`${route.name}-tab`}
                key={`${route.name}-tab`}
              >
                <a
                  className="static-tab-link"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setSelectedTab(route.name);
                  }}
                  href={route.href}
                >
                  {route.title}
                </a>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default StaticTabs;

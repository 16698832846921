import "./Subtitle.css";

const SubtitleGradient = () => {
  return (
    <div className="custom-subtitle">
      <h2 className="subtitle-centered-text subtitle-centered-text-gradient">
        Możesz jednak dobrać{" "}
        <span className="gradient-span">operatora płatności</span>, który będzie
        najlepszy w Twoim przypadku!
      </h2>
      <div className="background-line"></div>
    </div>
  );
};

export default SubtitleGradient;

import { useClickAway } from "react-use";
import { useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./NavMobile.css";
import { routes } from "../../route";

interface NavMobileProps {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

export const NavMobile: React.FC<NavMobileProps> = (props) => {
  const ref = useRef(null);

  const navigate = (tab: string) => {
    props.setSelectedTab(tab);
    props.setIsMenuOpen(false);
  };

  useClickAway(ref, (event: TouchEvent) => {
    //@ts-ignore
    if (event.target?.className === "dropdown-menu-item") {
      //@ts-ignore
      navigate((event.target?.hash as string).replace("#", ""));
    }

    if (
      //@ts-ignore
      event.target?.className === "hamburger-react" ||
      //@ts-ignore
      event.target?.offsetParent?.className === "hamburger-react" ||
      //@ts-ignore
      event.target?.offsetParent?.offsetParent?.className === "hamburger-react"
    ) {
      return;
    }
    props.setIsMenuOpen(false);
  });

  return (
    <div ref={ref} id="nav-mobile">
      <AnimatePresence>
        {props.isMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-neutral-950 border-b border-b-white/20"
          >
            <ul className="grid gap-2 mobile-menu-list">
              {routes.map((route, idx) => {
                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    className={`custom-dropdown-item ${
                      !route.main ? "custom-dropdown-item-fixed" : ""
                    } w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-neutral-800 via-neutral-950 to-neutral-700`}
                  >
                    <a
                      className="navmobile-menu-item"
                      onClick={() => navigate(route.name)}
                      href={route.href}
                    >
                      {route.title}
                    </a>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

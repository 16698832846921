import "./Table.css";
import { ReactComponent as TPaySvg } from "../../images/partners/tpay_logo_blue.svg";
import { ReactComponent as HotPaySvg } from "../../images/partners/logo-hotpay.svg";
import { ReactComponent as P24Svg } from "../../images/partners/Przelewy24_logo.svg";
import { ReactComponent as AutoPaySvg } from "../../images/partners/Logo-autopay.svg";
import { ReactComponent as PayUSvg } from "../../images/partners/PAYU_GPO-dark green[27].svg";
import { Provider } from "../../models/Provider";
import { Group } from "../../models/Group";
import { Information } from "../../models/Information";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export interface CustomTableProps {
  providers: Provider[];
  information: Information[];
  groups: Group[];
  registred: boolean;
  unRegistred: boolean;
}

const CustomTable: React.FC<CustomTableProps> = (props) => {
  const getValue = (provider: Provider, informationId: string) => {
    var value = Object.values(provider).find(
      (element) =>
        element &&
        typeof element === "object" &&
        element.InformationId === informationId
    )?.Value;

    if (typeof value == "boolean") {
      if (value === true) {
        return <CheckCircleIcon color="success" />;
      }
      return <HighlightOffIcon color="error" />;
    } else if (value == null) {
      return <RemoveCircleOutlineIcon color="disabled" />;
    } else if (typeof value == "string") {
      return value;
    }
  };

  return (
    <div className="table-container">
      <table className="custom-table">
        <thead>
          <tr key="header">
            <th className="cell-no-border"></th>
            {Array.from(
              props.providers.filter(
                (p) =>
                  (props.unRegistred && p.UnregistredCompany.Value) ||
                  !props.unRegistred ||
                  props.registred
              )
            ).map((provider) => {
              if (provider.ProviderName === "HotPay") {
                return (
                  <th key={provider.ProviderId}>
                    <div className="table-header-image">
                      <HotPaySvg className="table-responsive-svg" />
                      <a
                        href="https://hotpay.pl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="table-order-button"
                      >
                        Zamów
                      </a>
                    </div>
                  </th>
                );
              } else if (provider.ProviderName === "Przelewy24") {
                return (
                  <th key={provider.ProviderId}>
                    <div className="table-header-image">
                      <P24Svg className="table-responsive-svg" />
                      <a
                        href="https://www.przelewy24.pl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="table-order-button"
                      >
                        Zamów
                      </a>
                    </div>
                  </th>
                );
              } else if (provider.ProviderName === "PayU") {
                return (
                  <th key={provider.ProviderId}>
                    <div className="table-header-image">
                      <PayUSvg className="table-responsive-svg" />
                      <a
                        href="https://poland.payu.com/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="table-order-button"
                      >
                        Zamów
                      </a>
                    </div>
                  </th>
                );
              } else if (provider.ProviderName === "AutoPay") {
                return (
                  <th key={provider.ProviderId}>
                    <div className="table-header-image">
                      <AutoPaySvg className="table-responsive-svg" />
                      <a
                        href="https://autopay.pl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="table-order-button"
                      >
                        Zamów
                      </a>
                    </div>
                  </th>
                );
              } else if (provider.ProviderName === "TPay") {
                return (
                  <th key={provider.ProviderId}>
                    <div className="table-header-image">
                      <TPaySvg className="table-responsive-svg" />
                      <a
                        href="https://tpay.com/en"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="table-order-button"
                      >
                        Zamów
                      </a>
                    </div>
                  </th>
                );
              }
              return <></>;
            })}
          </tr>
        </thead>
        <tbody>
          {Array.from(props.groups).map((group) => {
            return (
              <>
                <tr key={group.GroupId + "row"}>
                  <td key={"blank"}></td>
                  <td key={group.GroupId} colSpan={props.providers.length}>
                    {group.Name}
                  </td>
                </tr>
                {Array.from(
                  props.information.filter((i) => i.GroupId === group.GroupId)
                ).map((info) => {
                  if (
                    props.registred &&
                    !props.unRegistred &&
                    info.Unregistred === true
                  ) {
                    return <></>;
                  } else if (
                    !props.registred &&
                    props.unRegistred &&
                    info.Unregistred === false
                  ) {
                    return <></>;
                  } else {
                    return (
                      <tr key={info.InformationId + "row"}>
                        <td key={info.InformationId}>{info.Name}</td>
                        {Array.from(
                          props.providers.filter(
                            (p) =>
                              (props.unRegistred &&
                                p.UnregistredCompany.Value) ||
                              !props.unRegistred ||
                              props.registred
                          )
                        ).map((provider) => (
                          <td key={provider.ProviderId + info.InformationId}>
                            {getValue(provider, info.InformationId)}
                          </td>
                        ))}
                      </tr>
                    );
                  }
                })}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
